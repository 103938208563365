.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Greyed-out background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-container {
  background: #383434;
  border: 1px solid #FFFFFF1A;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  position: relative;
  text-align: center;
  font-size: 24px;
}

@media (min-width: 550px) {
  .popup-container {
    max-width: 440px;
    margin: auto;
  }
}

.popup-fields {
  display: flex;
  flex-direction: column; 
  align-content: stretch;
  gap: 10px;
  margin: 10px 0 0 0;
}

.popup-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.7em;
}

.popup-fields input {
  background-color: #353232;
  color: white;
  margin: 0 0 0 3px;
  border: 1px solid #FFFFFF1A;
  font-size: 0.9em;
}

.popup-message {
  color: white;
  text-shadow: none;
  font-size: 1em;
}

.popup-actions {
  display: flex;
  justify-content: space-evenly;
  margin: 20px 0 0 0;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 1.5em;
  cursor: pointer;
}

.popup-button {
  border: 1px solid #FFFFFF1A;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  background: #353232;
  color: #FADD07;
  text-shadow: 3px 2px 0.1px #000000;
  font-size: 24px;
}

.popup-button:disabled {
  color: #444444
}

.confirm-button:hover, .cancel-button:hover {
  opacity: 0.8;
}
  