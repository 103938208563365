.shop-container {
    height: auto;
}

.product-image {
    width: 80%;
    margin: 20px;
}

.product {
    width: 80%;
    align-items: center;
    text-align: center;
}

.product-title {
    font-size: 140%;
    margin: 0;
}

.product-description {
    font-size: 100%;
    font-weight: bold;
    text-align: left;
    color: #ffffff;
    padding-left: 20px;
    margin-top: 0;
}

.description-bullet {
    margin: 0 0 5px 0;
}

.buy-button {
    margin: 15px 0 5px 0;
    padding: 15px 20px;
    border: none;
    background-color: #353232A6;
    font-size: 2em;
    border-radius: 30px;
    cursor: pointer;
    font-family: inherit;
    color: #FADD07;
    font-weight: 500;
}