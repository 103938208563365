@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;500;700&display=swap');

html {
  background-color: #1F1F1F;
}

body {
  margin: 0;
}

.box {
  text-align: center;
  justify-content: space-around;
  display: flex;
  border-radius: 20px;
  padding: 5px 20px;
  margin: 5px;
  background: #353232A6;
}

.hbox {
  display: flex;
  flex-direction: row;
}

.highlight {
  font-weight: bold;
  color: #FADD07;
}

.title {
  font-weight: bold;
  font-size: 130%;
  padding: 0;
  margin: 0;
}

.subtitle {
  font-weight: bold;
  font-size: 110%;
  color: white;
  padding: 0;
  margin: 0;
}

.App {
  font-family: 'Bai Jamjuree';
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

@media (min-width: 550px) {
  .App {
    max-width: 550px;
    margin: auto;
  }
}

.page {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.page-container {
  text-align: center;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  color: #DDDDDD;
  text-shadow:
    -1px -1px 0 black,
    0px -1px 0 black,
    1px -1px 0 black,
    1px 0px 0 black,
    1px 1px 0 black,
    0px 1px 0 black,
    -1px 1px 0 black,
    -1px 0px 0 black;
}

.scan-button {
  background-color: #FADD07C4;
  color: white;
  border-radius: 20px;
  border: none;
  font-weight: 600;
  font-size: 1em;
  width: 120px;
  padding: 5px;
  margin: 0 0 10px 0;
}

.scanner {
  border-radius: 10px;
  border: 2px solid #FFFFFF1A;
  box-shadow: 0 0 15px 10px #000000A1
}

.image-button {
  background-color: #00000000;
  border: none;
  padding: 0;
}

/* For long text */
.text-block {
  white-space: pre-wrap;
  text-shadow: none;
  text-align: justify;
  color: white;
  margin: 0px;
}

.subtitle-circled {
  font-weight: bold;
  font-size: 110%;
  padding: 0 5px;
  margin: 0;
  border: 2px solid #FADD07;
  box-shadow: 2px 2px 0.4px #000000;
  border-radius: 50%;
}

/* Header */

.App-header {
  display: flex;
  flex-direction: column;
  fill-opacity: 0;
  width: 100%;
  margin: 20px auto;
  gap: 15px;
  align-items: center;
  justify-items: center;
  position: relative;
}

.App-header-logo {
  width: 50%;
}

.header-links {
  background-color: #383434;
  padding: 5px;
}

.page-link {
  font-size: 1.2em;
  margin: 0 10px 0;
  text-decoration: none;
  position: relative;
  white-space: nowrap;
  font-weight: 500;
  color: #DDDDDD;
}

.page-link-current {
  font-weight: bold;
  /* color: #FADD07; */
}

/* Footer */

.App-footer {
  flex-direction: column;
  align-items: end;
  background-color: #2F2F2F;
  border: 1px solid #FFFFFF1A;
  margin: 10px;
  border-radius: 20px;
  padding: 10px;
}

.footer-link-container {
  align-items: stretch;
  display: flex;
  flex-direction: column;
}

.footer-page-link {
  white-space: nowrap;
  color: #FFFFFF;
  text-shadow: none;
}

.social-media-description {
  color: #DDDDDD;
  font-size: 0.7em;
  text-align: right;
  width: 100%;
  margin: 0 0 2px 0;
}

.social-media-logo {
  width: 50px;
  height: 50px;
  margin: 2px;
}

/* Video Page */

.video {
  max-width: 90%;
  max-height: 80vh;
  border-radius: 10px;
  margin: 5px 0 15px 0;
  text-shadow: none;
  border: 2px solid #FFFFFF1A;
  box-shadow: 0 0 15px 10px #000000A1
}

.video-title {
  font-size: 1.8em;
  padding: 0px 0px 5px 0px;
  font-weight: bold;
  z-index: 0;
  color: white;
}

.video-info {
  padding: 5px 10px;
  align-self: stretch;
  justify-content: space-between;
  flex-direction: row;
  text-shadow: none;
}

.video-date {
  text-align: center;
  font-weight: 500;
  font-size: 1.2em;
}

.view-count {
  font-size: 1.2em;
  font-weight: 500;
}

/* Upload Page */

.video-title-input {
  font-size: 1.5em;
  padding: 5px;
  margin: 5px;
  background: transparent;
  border: none;
  text-align: center;
  color: inherit;
  text-shadow: inherit;
}

.video-title-input::placeholder {
  color: #aaaaaa;
}

.video-input {
  margin: 10px;
  padding: 15px 20px;
  border: none;
  background-color: #353232A6;
  font-size: 36px;
  border-radius: 30px;
  cursor: pointer;
  font-family: inherit;
  color: #FADD07;
  font-weight: 500;
  text-shadow: none;
  align-content: center;
}

.video-input-selected {
  font-size: 1em;
}

input[type="file"] {
  display: none;
}

.upload-button {
  margin: 10px 5px;
  padding: 15px 20px;
  border: none;
  background-color: #353232A6;
  font-size: 1.5em;
  border-radius: 30px;
  cursor: pointer;
  font-family: inherit;
  color: #FADD07;
  font-weight: 500;
}

.progress-container {
  display: grid;
  margin-top: 10px;
  background-color: #e2e0e0;
  border-radius: 4px;
  overflow: hidden;
  border: 2px solid #000000;
  width: 50%;
  align-items: center;
}

.progress-value {
  position: relative;
  color: #000000;
  text-shadow: none;
  font-weight: bold;
  text-align: center;
  grid-area: 1 / 1;
}

.progress-bar {
  height: 20px;
  background-color: #FADD07;
  padding: 5px;
  position: relative;
  grid-area: 1 / 1;
}